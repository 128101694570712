<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Pengeluaran</b>
      </div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push({ name: 'expense-add' })"
      >Tambah</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table
              :items="items"
              :perPage="perPage"
              :currentPage="currentPage"
              :totalRows="totalRows"
              :recordData="recordData"
              @pageOnClick="pageOnClick"
              @btnDeleteOnClick="btnDeleteOnClick"
              @filterByPeriode="filterByPeriode"
              @filterByName="filterByName"
              @refreshPagination="refreshPagination"
            />
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import Card from '@/view/content/Card.vue'
import Table from '@/component/expenses/Table.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Table
  },

  data() {
    return {
      // Table Items
      items: [],
      // Filter
      filter: {
        name: '',
        start_date: '',
        end_date: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      recordData:0,
      // access management
      manipulateBtn: false
    }
  },

  methods: {

    async pagination() {
      let filterParams = `&name=${this.filter.name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let response = await module.paginate(`expenses`, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.totalRows = pagination.total
      this.recordData = pagination.total;

      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a
      }

      this.items = response.data
    },

    pageOnClick(page) {
      this.currentPage = page
      this.pagination()
    },

    refreshPagination(filter){
      this.filter.name = filter.name
      this.filter.start_date = filter.start_date
      this.filter.end_date = filter.end_date

      this.pagination()
    },

    filterByName(sended) {
      this.filter.start_date = sended.start_date
      this.filter.end_date = sended.end_date
      this.filter.name = sended.name
      this.pagination()
    },

    filterByPeriode(sended) {
      this.filter.start_date = sended.start_date
      this.filter.end_date = sended.end_date
      this.filter.name = sended.name
      this.pagination()
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete('expenses/' + id)
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

    // access management
    async setActiveMenu() {

      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {
        

        if (access_right[a] == "6005") {
          this.manipulateBtn = true
        }

      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Keuangan" },
      { title: "Pengeluaran" },
    ])
    // Get Data
    this.pagination()
    this.setActiveMenu()
  },

}
</script>

<style>
</style>